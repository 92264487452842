<template>
  <div class="user-center-container d-f f-d a-c">
    <nav class="d-f j-c a-c">
      <div class="middle d-f j-b a-c">
        <div class="left d-f a-c">
          <img :src="tenantLogo" width="32px" v-if="tenantLogo" />
          <img src="@/assets/images/layout_logo.png" width="32px" v-else />
          <span class="title">智慧校园管理平台</span>
          <span class="text">个人中心</span>
        </div>
        <div class="right d-f a-c">
          <img :src="avatarUrl" class="avatar" />
          <span class="username">{{ username }}</span>
          <div @click="back" class="back">
            <img src="@/assets/images/user_center_back.png" alt="头像" width="16px" />
            <span>返回</span>
          </div>
        </div>
      </div>
    </nav>
    <div class="content">
      <a-layout>
        <a-layout-sider width="230">
          <a-menu
            mode="inline"
            :default-selected-keys="defaultSelectedKeys"
            style="height: 100%"
          >
            <a-menu-item key="/user-center/base-setting">
              <router-link to="/user-center/base-setting" replace> 基本设置 </router-link>
            </a-menu-item>
            <a-menu-item key="/user-center/repair-pasword">
              <router-link to="/user-center/repair-pasword" replace>修改密码</router-link>
            </a-menu-item>
            <a-menu-item key="/user-center/message-notification">
              <router-link to="/user-center/message-notification" replace
                >消息通知</router-link
              >
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
        <a-layout-content :style="{ padding: '0 40px' }">
          <router-view @changeUserInfo="changeUserInfo"></router-view>
        </a-layout-content>
      </a-layout>
    </div>
  </div>
</template>

<script>
let avatar = require("@/assets/images/enter_avatar.png");
export default {
  name: "UserCenter",
  data() {
    return {
      avatarUrl: "",
      username: "",
    };
  },
  computed: {
    //默认选中的侧边栏
    defaultSelectedKeys() {
      return [this.$route.path];
    },
    //租户logo
    tenantLogo() {
      return this.$ls.get("userInfo").tenantLogo;
    },
  },
  methods: {
    //返回上一级路由
    back() {
      this.$router.go(-1);
    },
    //子组件传值（userInfo）
    changeUserInfo(value) {
      if (this.$ls.get("userInfo").headImgUrl) {
        this.avatarUrl = this.$ls.get("userInfo").headImgUrl;
      } else {
        this.avatarUrl = avatar;
      }
    },
  },
  created() {
    if (this.$ls.get("userInfo").headImgUrl) {
      this.avatarUrl = this.$ls.get("userInfo").headImgUrl;
    } else {
      this.avatarUrl = avatar;
    }
    this.username = this.$ls.get("userInfo").username;
  },
};
</script>

<style scoped lang="less">
.user-center-container {
  width: 100vw;
  min-height: 100vh;
  // min-width: 1440px;
  // overflow-x: auto;
  background: rgba(240, 242, 245, 0.39);
  opacity: 1;
  nav {
    width: 100%;
    height: 52px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 21, 41, 0.12);
    opacity: 1;
    .middle {
      width: 1000px;
      .left {
        .title {
          margin-left: 8px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
        .text {
          margin-left: 24px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .right {
        .avatar {
          width: 24px;
          height: 24px;
          background: rgba(212, 215, 217, 0.39);
          border-radius: 50%;
        }
        .username {
          margin-left: 8px;
          font-size: 14px;
          font-family: "PingFang SC";
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
        }
        .back {
          margin-left: 42px;
          cursor: pointer;
          span {
            margin-left: 2px;
            font-size: 14px;
            font-family: "PingFang SC";
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }
  .content {
    margin-top: 24px;
    width: 1000px;
    height: 700px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 0px 20px rgba(0, 21, 41, 0.05);
    opacity: 1;
    border-radius: 10px;
    .ant-layout {
      height: 100%;
      background: rgba(255, 255, 255, 0.39);
      padding-top: 42px;
      border-radius: 10px;
      .ant-layout-sider {
        background: rgba(255, 255, 255, 0.39);
        border-radius: 10px;
        .ant-menu {
          background: rgba(255, 255, 255, 0.39);
          border-radius: 0px 0px 0px 10px;
        }
      }
    }
  }
}
</style>
